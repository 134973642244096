@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,700);
body {
  padding-bottom: 40px;
  font-family: 'PT Sans', helvetica, arial;
  /*background-image: url('../images/background.jpg');
  background-size: cover;*/
}

.container-fluid {
  max-width: 1200px;
}

h1 {
  font-size: 70px;
  font-weight: 700;
  color: #24b9cc;
  margin-bottom: 10px;
}

h2 {
  color: #4c5e67;
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 32px;
  font-weight: 700;
}

select.form-control {
  -webkit-appearance: none;
}

section {
  padding: 50px 0;
}

section h1 {
  padding-bottom: 5px;
  border-bottom: 2px solid #24b9cc;
  margin-bottom: 30px;
}

.valore-mutuo {
  color: #24b9cc;
  font-size: 26px;
  font-weight: bold;
}

.valore-grande {
  color: #8bc34a;
  font-size: 52px;
  font-weight: bold;
}

.green {
  color: #8bc34a;
}

td.descrizione {
  max-width: 400px;
  vertical-align: top;
  padding-top: 20px;
  padding-bottom: 20px;
}

td.descrizione h2 {
  margin-top: 0;
}

td.descrizione p,
p.help {
  color: #97a8aa;
  font-size: 18px;
}

td.valore {
  vertical-align: top;
  padding-left: 30px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/*.container {
  background-color: #fff;
}*/

.logo {
  padding-top: 20px;
  margin-bottom: 30px;
  text-align: center;
}

.logo img {
  width: 70px;
}

.valore-immobile {
  position: relative;
  margin-bottom: 30px;
  background-color: #24b9cc;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  padding: 10px 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagine {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control {
  padding: 7px 12px 3px;
  font-weight: bold;
  color: #24b9cc !important;
  font-size: 26px;
}

.hidden-input {
  position: absolute;
  right: 10px;
  margin-left: 10px;
  width: 80px;
  background-color: #24b9cc;
  border-color: #24b9cc;
  color: #15a8bb !important;
}
/* #138d9d */

.strumento {
  margin-bottom: 20px;
}

.table thead th {
  /*background-color: #e8f2f3;
  color: #618d92;*/
  border: none;
}

.table th,
.table td {
  padding: 10px 16px;
  text-align: right;
  width: 14%;
  border: none;
  font-size: 20px;
}

.table td.valore {
  white-space: nowrap;
}

.table td.valore div {
  background: rgb(255, 255, 255);
  background: linear-gradient(275deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 1px 12px;
  border-radius: 10px;
}

